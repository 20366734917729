// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing {
    width: 100%;
    padding: 6rem 1rem;
    background: #000;
}
.card-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
}
.card {
    border: 1px solid #eee;
    color: #eee;
    text-align: center;
    padding: 1rem;
}
.card:hover {
    background-color: #141414;
}
.card h3 {
    font-size: 1.4rem;
    padding: 1rem;
}
.card p {
    padding: 10px 0;
}
.bar {
    border-bottom: 1px solid #eee;
    width: 10%;
    margin: 1.5rem auto;
    display: block;
}
.btc {
    font-size: 3rem;
    font-weight: 600;
    padding: 1rem 0;
}
.card .btn {
    display: block;
    width: 80%;
    margin: 2rem auto;
}
@media screen and (max-width: 740px) {
    .card-container {
        max-width: 90%;
        margin: auto;

        grid-template-columns: 1fr;
    }
}`, "",{"version":3,"sources":["webpack://./src/PricingCardStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,qCAAqC;IACrC,cAAc;AAClB;AACA;IACI,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,iBAAiB;IACjB,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,6BAA6B;IAC7B,UAAU;IACV,mBAAmB;IACnB,cAAc;AAClB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,cAAc;IACd,UAAU;IACV,iBAAiB;AACrB;AACA;IACI;QACI,cAAc;QACd,YAAY;;QAEZ,0BAA0B;IAC9B;AACJ","sourcesContent":[".pricing {\n    width: 100%;\n    padding: 6rem 1rem;\n    background: #000;\n}\n.card-container {\n    max-width: 1140px;\n    margin: auto;\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-gap: 40px;\n}\n.card {\n    border: 1px solid #eee;\n    color: #eee;\n    text-align: center;\n    padding: 1rem;\n}\n.card:hover {\n    background-color: #141414;\n}\n.card h3 {\n    font-size: 1.4rem;\n    padding: 1rem;\n}\n.card p {\n    padding: 10px 0;\n}\n.bar {\n    border-bottom: 1px solid #eee;\n    width: 10%;\n    margin: 1.5rem auto;\n    display: block;\n}\n.btc {\n    font-size: 3rem;\n    font-weight: 600;\n    padding: 1rem 0;\n}\n.card .btn {\n    display: block;\n    width: 80%;\n    margin: 2rem auto;\n}\n@media screen and (max-width: 740px) {\n    .card-container {\n        max-width: 90%;\n        margin: auto;\n\n        grid-template-columns: 1fr;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
