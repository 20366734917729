import React from 'react';
import HeroImg2 from './HeroImg2';
import Form from './Form';

const Contact = () => {
    return(
        <div>
            <HeroImg2/>
            <Form />
        </div>
    )
}

export default Contact;