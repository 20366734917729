import "./HeroImg2Styles.css";
import React from "react";

const HeroImg2 = () => {
    return (
        <div className="hero-img">
            <div className="heading">
                <h1>Напишите нам.</h1>
                <p>Для уточнения рабочих моментов</p>
            </div>
        </div>
    )
}
export default HeroImg2;