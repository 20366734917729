// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Kanit", sans-serif; 
}
body {
  background: #000;
}
h1,
h4,
p,
a {
  color: #fff;
  text-decoration: none;
}
ul {
  list-style-type: none;
}
.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248, 217, 15);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}
.btn-light {
  background: transparent;
  color: #fff;
  transform: 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;;AAGF;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,gCAAgC;AAClC;AACA;EACE,gBAAgB;AAClB;AACA;;;;EAIE,WAAW;EACX,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,6BAA6B;EAC7B,WAAW;EACX,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,uBAAuB;EACvB,WAAW;EACX,eAAe;AACjB","sourcesContent":["/*body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}*/\n@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  font-family: \"Kanit\", sans-serif; \n}\nbody {\n  background: #000;\n}\nh1,\nh4,\np,\na {\n  color: #fff;\n  text-decoration: none;\n}\nul {\n  list-style-type: none;\n}\n.btn {\n  padding: 12px 32px;\n  font-size: 1rem;\n  text-transform: uppercase;\n  background: rgb(248, 217, 15);\n  color: #222;\n  border: 1px solid #fff;\n  font-weight: 600;\n  cursor: pointer;\n}\n.btn-light {\n  background: transparent;\n  color: #fff;\n  transform: 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
