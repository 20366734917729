// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    width: 100%;
    padding: 6rem 0;
    background-color: rgba(19, 19, 19, 0.8);
}
.footer-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}
.left {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.location {
    display: flex;
    margin-bottom: 0.8rem;
}
.location p {
    line-height: 30px;
}
.right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.social {
    margin-top: 1rem;
}
h4 {
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
}
p {
    font-size: 1.2rem;
}
@media screen and (max-width: 640px) {
    .footer-container {
        grid-template-columns: 1fr;
    }
}`, "",{"version":3,"sources":["webpack://./src/FooterStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,uCAAuC;AAC3C;AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,qCAAqC;IACrC,cAAc;AAClB;AACA;IACI,YAAY;IACZ,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,aAAa;IACb,qBAAqB;AACzB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,sBAAsB;AAC1B;AACA;IACI,iBAAiB;AACrB;AACA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".footer {\n    width: 100%;\n    padding: 6rem 0;\n    background-color: rgba(19, 19, 19, 0.8);\n}\n.footer-container {\n    max-width: 1140px;\n    margin: auto;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-gap: 40px;\n}\n.left {\n    height: 100%;\n    max-width: 100%;\n    display: flex;\n    flex-direction: column;\n    padding: 1rem;\n}\n.location {\n    display: flex;\n    margin-bottom: 0.8rem;\n}\n.location p {\n    line-height: 30px;\n}\n.right {\n    height: 100%;\n    max-width: 100%;\n    display: flex;\n    flex-direction: column;\n    padding: 1rem;\n}\n.social {\n    margin-top: 1rem;\n}\nh4 {\n    font-size: 1.2rem;\n    padding-bottom: 0.7rem;\n}\np {\n    font-size: 1.2rem;\n}\n@media screen and (max-width: 640px) {\n    .footer-container {\n        grid-template-columns: 1fr;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
