// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
    color: #fff;
    margin-bottom: 0.5rem;
}
form {
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 600px;
}
form input, form textarea {
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    color: #f4f4f4;
}`, "",{"version":3,"sources":["webpack://./src/FormStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,0CAA0C;IAC1C,sCAAsC;IACtC,cAAc;AAClB","sourcesContent":["label {\n    color: #fff;\n    margin-bottom: 0.5rem;\n}\nform {\n    display: flex;\n    flex-direction: column;\n    padding: 4rem 1rem;\n    margin: auto;\n    max-width: 600px;\n}\nform input, form textarea {\n    margin-bottom: 1rem;\n    padding: 10px 18px;\n    font-size: 1.2rem;\n    background-color: rgba(255, 255, 255, 0.1);\n    border-color: rgba(255, 255, 255, 0.1);\n    color: #f4f4f4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
