// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}
.about .left {
    /*text-align: center;*/
    text-align: left;
    /*margin: auto;*/
    padding: 1rem;
    max-width: 750px;

    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #6a6a6a;
}
.about .left p {
    margin: 1.2rem 0;
}
.about .right {
    max-width: 700px;
}
.right .img-container {
    display: grid;
    grid-template-columns: repeat(120, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}
.right .img {
    max-width: 100%;
    /*border: 1px solid #333;*/
    /*width: 150%;
    height: 150%;*/
    
}
.right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}
.right .bottom {
    grid-column: 4 / -1;
    grid-row: 1;
    z-index: 5;
}
`, "",{"version":3,"sources":["webpack://./src/AboutContentStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,YAAY;AAChB;AACA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,gBAAgB;;IAEhB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,uCAAuC;IACvC,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,0BAA0B;IAC1B;kBACc;;AAElB;AACA;IACI,uBAAuB;IACvB,WAAW;IACX,gBAAgB;IAChB,UAAU;AACd;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,UAAU;AACd","sourcesContent":[".about {\n    width: 100%;\n    margin: 3rem 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-wrap: wrap;\n    height: 100%;\n}\n.about .left {\n    /*text-align: center;*/\n    text-align: left;\n    /*margin: auto;*/\n    padding: 1rem;\n    max-width: 750px;\n\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 28px;\n    letter-spacing: -0.4px;\n    color: #6a6a6a;\n}\n.about .left p {\n    margin: 1.2rem 0;\n}\n.about .right {\n    max-width: 700px;\n}\n.right .img-container {\n    display: grid;\n    grid-template-columns: repeat(120, 1fr);\n    position: relative;\n    align-items: center;\n    text-align: center;\n}\n.right .img {\n    max-width: 100%;\n    /*border: 1px solid #333;*/\n    /*width: 150%;\n    height: 150%;*/\n    \n}\n.right .top {\n    grid-column: 1 / span 8;\n    grid-row: 1;\n    padding-top: 20%;\n    z-index: 6;\n}\n.right .bottom {\n    grid-column: 4 / -1;\n    grid-row: 1;\n    z-index: 5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
