// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mask {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .into-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  }
  .mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.8;
  }
  .hero {
    height: 100%;
    width: 100%;
  }
  .hero .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
  }
  .hero .content h1 {
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
  }
  .hero .content p {
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
  }
  .content .btn {
    margin: 1rem 0.2rem;
  }
  /*@media screen and (max-width: 640px) {
    .hero {
        height: 20%;
        width: 20%;
      }
    /*.hero .content h1 {
      font-size: 3rem;
    }
    .hero .content p {
      font-size: 1.4rem;
    }
    .hero {
      padding: 70%;
    }
  }
  @media screen and (max-width: 430px) {
    .hero {
      height: 50%;
      width: 50%;
    }
  }*/
`, "",{"version":3,"sources":["webpack://./src/MainPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,2BAA2B;IAC3B,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,sBAAsB;IACtB,YAAY;EACd;EACA;IACE,YAAY;IACZ,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,gCAAgC;IAChC,QAAQ;IACR,SAAS;IACT,kBAAkB;EACpB;EACA;IACE,eAAe;IACf,wBAAwB;EAC1B;EACA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;EAC3B;EACA;IACE,mBAAmB;EACrB;EACA;;;;;;;;;;;;;;;;;;;;IAoBE","sourcesContent":[".mask {\n    width: 100%;\n    height: 100%;\n    position: relative;\n  }\n  .into-img {\n    width: 100%;\n    height: 100%;\n    background-size: cover;\n    background-position: center;\n    object-fit: cover;\n  }\n  .mask::after {\n    content: \"\";\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    background-color: #000;\n    opacity: 0.8;\n  }\n  .hero {\n    height: 100%;\n    width: 100%;\n  }\n  .hero .content {\n    position: absolute;\n    transform: translate(-50%, -50%);\n    top: 50%;\n    left: 50%;\n    text-align: center;\n  }\n  .hero .content h1 {\n    font-size: 4rem;\n    padding: 0.6rem 0 1.5rem;\n  }\n  .hero .content p {\n    font-size: 1.4rem;\n    font-weight: 200;\n    text-transform: uppercase;\n  }\n  .content .btn {\n    margin: 1rem 0.2rem;\n  }\n  /*@media screen and (max-width: 640px) {\n    .hero {\n        height: 20%;\n        width: 20%;\n      }\n    /*.hero .content h1 {\n      font-size: 3rem;\n    }\n    .hero .content p {\n      font-size: 1.4rem;\n    }\n    .hero {\n      padding: 70%;\n    }\n  }\n  @media screen and (max-width: 430px) {\n    .hero {\n      height: 50%;\n      width: 50%;\n    }\n  }*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
