// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-img {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 60vh;
    position: relative;
}
.hero-img::before {
    content: "";
    background: url("https://mckups.com/wp-content/uploads/2020/08/imac-on-a-table-preview-2000x1333.jpg");
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hero-img h1 {
    font-size: 2.4rem;
}
.hero-img p {
    font-size: 1.4rem;
    text-align: center;
}
@media screen and (max-width: 640px) {
    .hero-img h1 {
        font-size: 2rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/HeroImg2Styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,8BAA8B;IAC9B,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,sGAAsG;IACtG,sBAAsB;IACtB,+BAA+B;IAC/B,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;AACf;AACA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".hero-img {\n    width: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    height: 60vh;\n    position: relative;\n}\n.hero-img::before {\n    content: \"\";\n    background: url(\"https://mckups.com/wp-content/uploads/2020/08/imac-on-a-table-preview-2000x1333.jpg\");\n    background-size: cover;\n    background-position: center top;\n    height: 100%;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: -1;\n}\n.heading {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.hero-img h1 {\n    font-size: 2.4rem;\n}\n.hero-img p {\n    font-size: 1.4rem;\n    text-align: center;\n}\n@media screen and (max-width: 640px) {\n    .hero-img h1 {\n        font-size: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
