// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-heading {
    text-align: center;
    padding: 4rem 0 2rem 0;
}
.project-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}
.project-card {
    background: #1a1919;
    padding: 1.2rem 1rem;
}
.project-card img {
    width: 100%;
}
.project-title {
    color: #fff;
    padding: 1rem 0;
}
.pro-details p {
    padding-bottom: 1rem;
    font-size: 1.1rem;
    /*text-align: justify;*/
    word-spacing: 5px;
    /*padding: 5%;*/
}
.pro-btns {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}
.pro-btns .btn {
    padding: 0.5rem 1rem;
}
@media screen and (max-width: 740px) {
    .project-container {
        max-width: 90%;
        margin: auto;

        grid-template-columns: 1fr; 
    }
}`, "",{"version":3,"sources":["webpack://./src/WorkCardStyles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,qCAAqC;IACrC,cAAc;IACd,oBAAoB;AACxB;AACA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,oBAAoB;IACpB,iBAAiB;IACjB,uBAAuB;IACvB,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;AACrB;AACA;IACI,oBAAoB;AACxB;AACA;IACI;QACI,cAAc;QACd,YAAY;;QAEZ,0BAA0B;IAC9B;AACJ","sourcesContent":[".project-heading {\n    text-align: center;\n    padding: 4rem 0 2rem 0;\n}\n.project-container {\n    max-width: 1140px;\n    margin: auto;\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-gap: 40px;\n    padding-bottom: 5rem;\n}\n.project-card {\n    background: #1a1919;\n    padding: 1.2rem 1rem;\n}\n.project-card img {\n    width: 100%;\n}\n.project-title {\n    color: #fff;\n    padding: 1rem 0;\n}\n.pro-details p {\n    padding-bottom: 1rem;\n    font-size: 1.1rem;\n    /*text-align: justify;*/\n    word-spacing: 5px;\n    /*padding: 5%;*/\n}\n.pro-btns {\n    display: flex;\n    justify-content: space-between;\n    padding: 0.5rem 0;\n}\n.pro-btns .btn {\n    padding: 0.5rem 1rem;\n}\n@media screen and (max-width: 740px) {\n    .project-container {\n        max-width: 90%;\n        margin: auto;\n\n        grid-template-columns: 1fr; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
