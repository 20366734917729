const ProjectCardData = [
    {
        imgsrc: "./images/dcu51.png",
        title: "Сайт Визитка (рекламный)",
        text: "Небольшой сайт, как правило, состоящий из одной веб-страниц и содержащий основную информацию об организации, частном лице, компании, товарах или услугах, прейскуранты, контактные данные. Часто сайт-визитку используют компании, которые не хотят нести большие затраты на создание отдельного сайта",
        view: ""
    },
    {
        imgsrc: "./images/vitrina6.1.png",
        title: "E-commerce (интернет магазин)",
        text: "Электронная коммерция — это бизнес, связанный с покупкой и продажей товаров и услуг через Интернет. Заказчики могут совершать покупки со своих компьютеров и через другие точки взаимодействия, включая смартфоны",
        view: ""
    },
    {
        imgsrc: "./images/24logo.jpg",
        title: "Экспресс",
        text: "Быстро. Сайт полностью за 24 часа.",
        view: ""
    },
]

export default ProjectCardData;